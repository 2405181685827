import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Form, Container, ListGroup } from 'react-bootstrap';
import { gql } from '@apollo/client';
import ReactMarkdown from 'react-markdown';
import "./../index.css"

// GraphQL mutation
const ASK_ASSISTANT = gql`
    mutation askAssistant($message: String!, $threadId: String) {
        askAssistant(message: $message, threadId: $threadId) {
            threadId
            messages {
                text
                role
            }
        }
    }
`;

function Assistant(props) {
    const [message, setMessage] = useState("");
    const [threadId, setThreadId] = useState(null); // Replace with actual thread ID as needed
    const [conversation, setConversation] = useState([]);

    // Apollo mutation hook
    const [askAssistant, { data, loading, error }] = useMutation(ASK_ASSISTANT,
        {context: {
        headers: {
            "Authorization": props.pluginUserCredential
        }
    }});

    // Send message handler
    const sendMessage = async () => {
        const { data } = await askAssistant({ variables: { message, threadId } });
        if (data) {
            const newMessages = [...data.askAssistant.messages].reverse();
            setConversation(newMessages);
            setThreadId(data.askAssistant.threadId); // Update thread ID if a new one was created
        }
    };

    return (
        <Container className="full-height-flex">
            <h1>Recombee Assistant Chat</h1>
            <ListGroup>
                {conversation.map((msg, idx) => (
                    <ListGroup.Item key={idx} variant={msg.role === 'user' ? 'primary' : 'secondary' } className="text-start">
                        <ReactMarkdown>{msg.text}</ReactMarkdown>
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Form>
                <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </Form.Group>
                <Button variant="primary" onClick={sendMessage} disabled={loading} className="m-2">
                    Send
                </Button>
            </Form>
            {error && <p>Error: {error.message}</p>}
        </Container>
    );
}

export default Assistant;