import Table from 'react-bootstrap/Table'
import OrganizationBillingStatus from "./OrganizationBillingStatus";

function OrganizationsTable(props) {

    const organizations = props.organizations;

    if (organizations.length === 0) {
        return null;
    }

    let orgsHtml = organizations.map((org) => (<tr><td><a href={org.link} target="_blank">{org.id}</a></td>
                                                    <td><OrganizationBillingStatus billingStatus={org.billingStatus} /></td>
                                               </tr>));

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Organization ID</th>
                <th>Plan</th>
            </tr>
            </thead>
            {orgsHtml}
        </Table>
    );
}

export default OrganizationsTable;