import Table from 'react-bootstrap/Table'
import OrganizationBillingStatus from "./OrganizationBillingStatus";

function DatabasesTable(props) {

    const databases = props.databases;

    if (databases.length === 0) {
        return null;
    }

    let dbsHtml = databases.map((db) => (<tr><td><a href={db.link} target="_blank">{db.id}</a></td>
                                             <td><a href={db.organization.link} target="_blank">{db.organization.id}</a></td>
                                             <td><OrganizationBillingStatus billingStatus={db.organization.billingStatus} /></td>
                                         </tr>));

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Database ID</th>
                <th>Organization ID</th>
                <th>Plan</th>
            </tr>
            </thead>
            {dbsHtml}
        </Table>
    );
}

export default DatabasesTable;