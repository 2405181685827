import {useState} from "react";
import jwt_decode from "jwt-decode";
import {isRecombeeEmail} from "../util";
import {Container} from "react-bootstrap";



function GoogleSignIn(props) {
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)

    const LOCAL_STORAGE_TOKEN_KEY = "googleSignInToken";

    const handleGoogleSignIn = (res) => {
        if (!res.clientId || !res.credential) return;
        const decoded = jwt_decode(res.credential);

        if (isRecombeeEmail(decoded.email)) {
            props.setPluginUserCredential(res.credential);
        }
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, res.credential);
    }

    const handleTokenFromLocalStorage = () => {
        const credential = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

        if (credential) {
            const decoded = jwt_decode(credential);
            const currentTime = Date.now() / 1000;

            if (decoded.exp > currentTime) {
                props.setPluginUserCredential(credential);
                return true;
            }
        }
        return false;
    }

    const initializeGsi = () => {

        if (handleTokenFromLocalStorage()) return;

        // Typescript will complain about window.google
        // Add types to your `react-app-env.d.ts` or //@ts-ignore it.
        if (!window.google || gsiScriptLoaded) return;


        setGsiScriptLoaded(true)
        window.google.accounts.id.initialize({
            client_id: "703591895799-igu0h2sfie0uvngf5i101gft0doj2snm.apps.googleusercontent.com",
            callback: handleGoogleSignIn,
        })
        window.google.accounts.id.renderButton(
            document.getElementById("signInButtonDiv"),
            { theme: "outline", size: "large" }  // customization attributes
        );
    }

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGsi;
    script.async = true;
    script.id = "google-client-script";
    document.querySelector("body")?.appendChild(script);

    return (<Container><div id="signInButtonDiv" /></Container>)
}

export default GoogleSignIn;