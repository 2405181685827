import {
    useQuery,
    gql
} from "@apollo/client";

import OrganizationsTable from "./OrganizationsTable";
import DatabasesTable from "./DatabasesTable";

function isRecombeeEmail(emailAddress) {
    return emailAddress.includes("@recombee.com")
}

function OrganizationsDatabasesInfo(props) {

    const recipient = props.recipient;


    const ADMIN_QUERY = gql`
    query AdminQuery($email: String!) {
        admin(email: $email) {
            email
            exists
            link
            organizations {
                id
                link
                billingStatus
            }
            databases {
                id
                link
                organization {
                    id
                    link
                    billingStatus
                }
            }
        }
    }`;

    const { loading, error, data } = useQuery(ADMIN_QUERY, {
        variables: {'email': recipient},
        context: {
            headers: {
                "Authorization": props.pluginUserCredential
            }
        }
    });
    if (isRecombeeEmail(recipient)) return <p>{recipient} is a Recombee employee</p>;

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    if (data.admin.exists === false) {
        return <p>{recipient} is not registered in Recombee Admin</p>;
    }

    return  (
        <div>
            <p><a href={data.admin.link} target="_blank">{recipient}</a></p>
            <OrganizationsTable organizations={data.admin.organizations} />
            <DatabasesTable databases={data.admin.databases} />
        </div>
    );
}

export default OrganizationsDatabasesInfo;