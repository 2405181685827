import {Badge} from "react-bootstrap";

function OrganizationBillingStatus(props) {

    const status = props.billingStatus;

    let bg = "success";
    if (status === "FREE") {
        bg = "secondary";
    } else if (status === "TRIAL") {
        bg = "warning";
    }

    return (
        <Badge bg={bg}>{status}</Badge>
    );
}

export default OrganizationBillingStatus;